
<template>
    <section class="py-5 banner-bg bs-shadow mobile-top">
      <div class="container">
        <h1 class="text-white mb0 lh1">{{title}}</h1>
        <hr class="header-divide" />
        <div class="search-btns">
          <div class="left-side" style="margin-right: 20px">
            <div class="dates" id="dates">
              <h5 class="text-white mb0 lh1">
                {{desc}}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>


<script>
// used in joint projects
  export default {
        name: 'Banner1',
        props: ['title', 'desc'],
  }
</script>


<style scoped>
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.banner-bg {
background-color: #00BCBC;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'%3E%3Cg transform='rotate(0 0 0)' style='transform-origin:center'%3E%3Cg transform='rotate(0 0 0)' style='transform-origin:center'%3E%3Cg transform='rotate(0 0 0)' style='transform-origin:center'%3E%3Cg id='c'%3E%3Cg id='b'%3E%3Cuse href='%23a' y='-1400'/%3E%3Cuse href='%23a' y='-1300'/%3E%3Cuse href='%23a' y='-1200'/%3E%3Cuse href='%23a' y='-1100'/%3E%3Cuse href='%23a' y='-1000'/%3E%3Cuse href='%23a' y='-1000'/%3E%3Cuse href='%23a' y='-900'/%3E%3Cuse href='%23a' y='-800'/%3E%3Cuse href='%23a' y='-700'/%3E%3Cuse href='%23a' y='-600'/%3E%3Cuse href='%23a' y='-500'/%3E%3Cuse href='%23a' y='-400'/%3E%3Cuse href='%23a' y='-300'/%3E%3Cuse href='%23a' y='-200'/%3E%3Cuse href='%23a' y='-100'/%3E%3Cg stroke='%2300B4B4' stroke-width='0' id='a'%3E%3Cpath fill='%2300B4B4' d='M0 0l2100 40l-2100 40z'/%3E%3C/g%3E%3Cuse href='%23a' y='100'/%3E%3Cuse href='%23a' y='200'/%3E%3Cuse href='%23a' y='300'/%3E%3Cuse href='%23a' y='400'/%3E%3Cuse href='%23a' y='500'/%3E%3Cuse href='%23a' y='600'/%3E%3Cuse href='%23a' y='700'/%3E%3Cuse href='%23a' y='800'/%3E%3Cuse href='%23a' y='900'/%3E%3Cuse href='%23a' y='1000'/%3E%3Cuse href='%23a' y='1100'/%3E%3Cuse href='%23a' y='1200'/%3E%3Cuse href='%23a' y='1300'/%3E%3Cuse href='%23a' y='1400'/%3E%3Cuse href='%23a' y='1500'/%3E%3Cuse href='%23a' y='1600'/%3E%3Cuse href='%23a' y='1700'/%3E%3Cuse href='%23a' y='1800'/%3E%3Cuse href='%23a' y='1900'/%3E%3Cuse href='%23a' y='2000'/%3E%3Cuse href='%23a' y='2100'/%3E%3Cuse href='%23a' y='2200'/%3E%3Cuse href='%23a' y='2300'/%3E%3Cuse href='%23a' y='2400'/%3E%3Cuse href='%23a' y='2500'/%3E%3Cuse href='%23a' y='2600'/%3E%3Cuse href='%23a' y='2700'/%3E%3Cuse href='%23a' y='2800'/%3E%3C/g%3E%3Cuse href='%23b' x='-1' transform='scale(-1 1)'/%3E%3C/g%3E%3Cuse href='%23c' x='' transform='rotate(90 0 0)' style='transform-origin:center'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}
</style>